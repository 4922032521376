<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <HeroAlt :hero-alt="heroAlt" />
        <v-container class="py-16">
          <v-row class="py-16">
            <v-col cols="12">
              <h2
                class="text-h4 text-md-h3 text-center font-weight-black text-capitalize"
              >
                "Lugar Alegre para Trabajar"
              </h2>
              <br />
            </v-col>
            <v-col cols="12" md="6">
              <p>
                El Centro de Formación y Competencias IntegRHales, entrega a las empresas
                no gubernamentales de diferentes giros comerciales la oportunidad de
                obtener el distintivo “Lugar Alegre Para Trabajar”. De esta manera se
                convierten en portavoces de las buenas prácticas empresariales en términos
                de felicidad y clima laboral.
              </p>
              <blockquote class="text-h4 pa-5 my-4 font-weight-thin">
                ... Insignia LAT promueve la Felicidad Laboral y el bienestar de su
                capital humano.
              </blockquote>
              <p>
                El distintivo LAT (Lugar alegre para trabajar) acredita a la empresa ante
                sus públicos de interés, que asumen voluntaria y públicamente el
                compromiso de implantación y mejora continua de una gestión enfocada a
                mejorar el bienestar y felicidad de su personal como parte de su cultura y
                estrategia de negocio.
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <p>
                Este compromiso se encuentra reflejado en sus políticas, estrategias y
                programas abarcando integralmente todos los ámbitos y niveles de actuación
                de la empresa.
                <br />
              </p>
              <h4 class="text-h5 text-center font-weight-black">Acerca de la insignia</h4>
              <br />
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>Comercial</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    Aumenta las ventas al diferenciar sus productos y servicios de la
                    competencia
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>Laboral</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    Facilita el reclutamiento de personal de primer nivel y la retención
                    de talentos
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>Financiero</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    Incrementa la confianza de accionistas
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>Reputación</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    Mejora la imagen pública frente a sus grupos de interés y aumenta la
                    fidelidad de los clientes
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>Marketing</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    Permite mostrarse como una empresa comprometida con las personas y su
                    crecimiento como seres humanos
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6" align-self="center">
              <v-img max-height="800" src="@/assets/lat-2.jpg"> </v-img>
            </v-col>
            <v-col cols="12" md="6" align-self="center">
              <div class="pa-lg-16 pa-md-10 px-4 py-16">
                <h2 class="text-h3 text-center font-weight-black">
                  Ventajas competitivas
                </h2>
                <br />
                <p>
                  Proyección en medios digitales: EJ Partners, a través de sus redes
                  sociales, emitirá comunicados a su red de seguidores, haciendo menciones
                  de las empresas portadoras del distintivo LAT.
                </p>
                <p>
                  Sitio web: Las empresas con el distintivo LAT aparecerán en la página
                  oficial de AICIT www.aicit.com para que sus públicos puedan comprobar la
                  veracidad de la información Reconocimiento: El consultor de AICIT que
                  haya atendido a la empresa, entregará un reconocimiento a la misma con
                  el distintivo impreso y con las fechas que abarca su membresía anual.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import HeroAlt from "@/components/sections/HeroAlt";
export default {
  components: {
    HeroAlt,
  },
   mounted() {
    window.scrollTo(0, 0);
  },

  data() {
    return {
      heroAlt: [
        {
          src: "lat-1.jpg",
          heading: " Insignia LAT ",
        },
      ],
    };
  },
};
</script>
